var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GoBack',{attrs:{"title":"User Profile Settings"}}),_c('ValidationObserver',{ref:"observer",staticClass:"profile",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('section',{staticClass:"form"},[_c('div',{staticClass:"form__row form__row--mb-50"},[_c('div',{staticClass:"form__row_col form__row_col--side"},[_c('div',{staticClass:"profile__img"},[_c('UploadImage',{staticClass:"image--lg",attrs:{"image":_vm.user.avatar,"initials":_vm.user.initials},on:{"upload":_vm.upload}}),_c('div',{staticClass:"profile__img_text"},[_c('h6',[_vm._v(_vm._s(_vm.user.name_abbreviation))]),_c('p',[_vm._v("Collector")])])],1)]),_c('div',{staticClass:"form__row_col form__row_col--side"},[_c('div',{staticClass:"form__actions"},[_c('BaseButton',{staticClass:"button--sm button--outline button--uppercase",attrs:{"text":"change password"},on:{"click":function($event){return _vm.$router.push({ path: ("/change-password?email=" + (_vm.user.email) + "&reset_password=1") })}}}),_c('BaseButton',{staticClass:"button--sm button--black button--uppercase",attrs:{"text":"logout"},on:{"click":function($event){$event.preventDefault();return _vm.apiLogoutUser.apply(null, arguments)}}})],1)])]),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"First Name","required":true,"validateError":errors[0],"element":_vm.user.first_name},on:{"input":function($event){_vm.user.first_name = $event}},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Last Name","required":true,"validateError":errors[0],"element":_vm.user.last_name},on:{"input":function($event){_vm.user.last_name = $event}},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Email","required":true,"validateError":errors[0],"element":_vm.user.email},on:{"input":function($event){_vm.user.email = $event}},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)}),_c('div',{staticClass:"form__row_col form__row_col--side"},[_c('BaseInput',{attrs:{"placeholder":"Phone","element":_vm.user.phone},on:{"input":function($event){_vm.user.phone = $event}},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})],1)],1),(_vm.user.new_email)?_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__row_col form__row_col--side"},[_c('BaseInput',{staticClass:"form-control--icon form-control--icon-15",attrs:{"placeholder":"New Email","icon":true,"disabled":true,"element":_vm.user.new_email},on:{"input":function($event){_vm.user.new_email = $event}},scopedSlots:_vm._u([{key:"btn",fn:function(){return [_c('BaseButton',{staticClass:"button--xsm button--outline button--uppercase",attrs:{"text":"verify","loading":_vm.verifyLoading,"textColor":"#000"},on:{"click":_vm.verify}})]},proxy:true}],null,true),model:{value:(_vm.user.new_email),callback:function ($$v) {_vm.$set(_vm.user, "new_email", $$v)},expression:"user.new_email"}})],1)]):_vm._e()]),_c('div',{staticClass:"form__actions"},[_c('BaseButton',{staticClass:"button--sm button--outline button--uppercase",attrs:{"text":"Discard Changes","disabled":!_vm.changed},on:{"click":_vm.discardChanges}}),_c('BaseButton',{staticClass:"button--sm button--green button--uppercase",attrs:{"text":"Save Changes","loading":_vm.loading,"disabled":!_vm.changed || invalid},on:{"click":_vm.saveChanges}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }